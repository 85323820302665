<template>
  <div>
    <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-plant type="none" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 평가기간 -->
          <c-datepicker
            :range="true"
            defaultStart="-1y"
            defaultEnd="1M"
            label="LBL0000607"
            name="assessmentDates"
            v-model="searchParam.assessmentDates"
          />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            codeGroupCd="RAM_ASSESS_TYPE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ramAssessTypeCd"
            label="평가구분"
            v-model="searchParam.ramAssessTypeCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 평가기법 -->
          <c-select
            codeGroupCd="RAM_TECHNIQUE_CD"
            type="search"
            itemText="codeName"
            itemValue="code"
            name="ramTechniqueCd"
            label="LBL0001031"
            v-model="searchParam.ramTechniqueCd"
          ></c-select>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            type="search"
            label="평가부서"
            name="deptCd"
            v-model="searchParam.deptCd">
          </c-dept>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 공정 -->
          <c-process
            label="LBLPROCESS"
            name="processCd"
            v-model="searchParam.processCd">
          </c-process>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 개선진행단계 -->
          <c-select
            stepperGrpCd="IBM_STEP_CD"
            type="search"
            itemText="stepperMstNm"
            itemValue="stepperMstCd"
            name="ibmStepCd"
            label="LBL0001409"
            v-model="searchParam.ibmStepCd"
          ></c-select>
        </div>
      </template>
    </c-search-box>
    <!-- 위험등록부 목록 -->
    <c-table
      ref="table"
      title="LBL0001203"
      tableId="riskRegister01"
      :columns="gridRisk.columns"
      :data="gridRisk.data" 
      :merge="gridRisk.merge"
      :editable="editable"
      @linkClick="linkClick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props }">
        <template v-if="props.row['sopImprovementIds'] && props.row['ibmStepNames']">
          <q-chip
            v-for="(item, index) in $comm.ibmTagItems(props.row)"
            :key="index"
            outline square
            :color="item.color"
            :clickable="true"
            text-color="white"
            style="margin-bottom:4px !important;"
            @click="openImpr(item, props.row)">
            {{item.title}}
            <c-assess-tooltip 
              :ibmTooltip="item.ibmTooltip"
              :ibmClassCd="item.ibmClassCd"
            />
          </q-chip>
        </template>
        <template v-else>
        </template>
      </template>
    </c-table>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'assess-risk-register',
  props: {
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        assessmentDates: [],
        ramAssessTypeCd: null,
        ramTechniqueCd: null,
        deptCd: null,
        processCd: null,
        ibmStepCd: null,
      },
      gridRisk: {
        merge: [
          { index: 0, colName: 'ramTechniqueCd' },
          { index: 1, colName: 'ramRiskAssessmentPlanId' },
          { index: 2, colName: 'ramRiskAssessmentPlanId' },
          { index: 3, colName: 'ramRiskAssessmentPlanId' },
          { index: 4, colName: 'processCd' },
          { index: 5, colName: 'assessItem' },
        ],
        data: [],
        columns: [
          {
            name: 'ramTechniqueName',
            field: 'ramTechniqueName',
            // 평가기법
            label: 'LBL0001031',
            align: 'center',
            style: 'width:80px',
            sortable: false,
          },
          {
            name: 'assessmentName',
            field: 'assessmentName',
            // 평가명
            label: 'LBL0000367',
            align: 'left',
            style: 'width:130px',
            sortable: false,
          },
          {
            name: 'deptName',
            field: 'deptName',
            label: '평가<br/>부서',
            align: 'center',
            style: 'width:80px',
            sortable: false
          },
          {
            name: 'ramAssessTypeName',
            field: 'ramAssessTypeName',
            label: '평가<br/>구분',
            align: 'center',
            style: 'width:50px',
            sortable: false
          },
          {
            name: 'processName',
            field: 'processName',
            // 공정
            label: 'LBLPROCESS',
            align: 'center',
            style: 'width:100px',
            sortable: false,
          },
          {
            name: 'assessItem',
            field: 'assessItem',
            label: '평가항목(위험요인)',
            align: 'left',
            style: 'width:400px',
            sortable: false,
          },
          {
            name: 'afterRisk',
            field: 'afterRisk',
            label: '개선 후<br/>위험도',
            align: 'center',
            style: 'width:70px',
            sortable: false,
          },
          {
            name: 'assessDate',
            field: 'assessDate',
            // 평가일
            label: 'LBL0001013',
            align: 'center',
            style: 'width:90px',
            sortable: false,
          },
          {
            name: 'assessUserName',
            field: 'assessUserName',
            // 평가자
            label: 'LBL0001014',
            align: 'center',
            style: 'width:80px',
          },
          {
            name: 'impr',
            field: 'impr',
            // 개선진행
            label: 'LBL0001178',
            align: 'center',
            child: [
              {
                name: 'customCol',
                field: 'customCol',
                label: 'LBLIMPRNO_PROGRESS',
                align: 'center',
                style: 'width:150px',
                type: 'custom',
                sortable: false
              },
            ]
          },
        ],
      },
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '80%',
        param: {},
        closeCallback: null,
      },
      requestImprRow: null,
      imprData: null,
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.ram.riskRegister.list.url
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.gridRisk.data = _result.data;
      },);
    },
    /* eslint-disable no-unused-vars */
    linkClick(row, col, index) {
      // RT00000001	HAZOP         
      // RT00000005	K-PSR         
      // RT00000010	Check-list    
      // RT00000015	JSA           
      // RT00000020	KRAS          
      // RT00000025	4M     
      // RT00000030 CHARM       
      let pageSrc = '';
      switch (row.ramTechniqueCd) {
        case 'RT00000001':
          pageSrc = '@/pages/ram/hazop/hazopDetail'
          break;
        case 'RT00000005':
          pageSrc = '@/pages/ram/kpsr/kpsrDetail'
          break;
        case 'RT00000010':
          pageSrc = '@/pages/ram/checklist/checklistDetail'
          break;
        case 'RT00000015':
          pageSrc = '@/pages/ram/jsa/jsaDetail'
          break;
        case 'RT00000020':
          pageSrc = '@/pages/ram/kras/krasDetail'
          break;
        case 'RT00000025':
          pageSrc = '@/pages/ram/4m/4mDetail'
          break;
        case 'RT00000030':
          pageSrc = '@/pages/ram/charm/charmDetail'
          break;
      }
      this.popupOptions.title = 'LBL0001040'; // 위험성평가 상세
      this.popupOptions.param = {
        ramRiskAssessmentPlanId: row.ramRiskAssessmentPlanId,
        ramStepCd: row.ramStepCd,
      };
      this.popupOptions.target = () => import(`${pageSrc}`);
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closePopup;
    },
    closePopup() {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.getList();
    },
    openImpr(data, row) {
      this.requestImprRow = row
      this.imprData = data;
      this.popupOptions.title = 'LBLIMPROVE';
      this.popupOptions.param = {
        sopImprovementId: data.sopImprovementId,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprDetail.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeImprPopup;
    },
    closeImprPopup(result) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (result && result.col1) {
        // if (result.col1.ibmStepCd === 'IS00000010' || result.col1.ibmStepCd === 'IS00000015' || result.col1.ibmStepCd === 'IS00000020') {
        //   this.requestImprRow.riskRegisterFlag = true
        // }
        let splitId = this.$_.split(this.requestImprRow.sopImprovementIds, ',');
        let splitStep = this.$_.split(this.requestImprRow.ibmStepNames, ',');
        if (splitId && splitId.length > 0) {
          let idIndex = this.$_.indexOf(splitId, result.col1.sopImprovementId);
          splitStep[idIndex] = result.col1.ibmStepName;
          this.requestImprRow.ibmStepNames = splitStep.toString();
        }
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.getList();
      }
    },
    /* eslint-disable no-unused-vars */
    innerBtnClicked(col, props, btn) {
      // RT00000001	HAZOP         ITT0000001	HAZOP
      // RT00000005	K-PSR         ITT0000005	K-PSR
      // RT00000010	Check-list    ITT0000010	CHECKLIST
      // RT00000015	JSA           ITT0000015	JSA
      // RT00000020	KRAS          ITT0000020	KRAS
      // RT00000025	4M            ITT0000023	4M
      // RT00000030 CHARM         ITT0000024  CHARM
      this.requestImprRow = props.row
      let ibmTaskTypeCd = '';
      let ibmTaskUnderTypeCd = '';
      switch (props.row.ramTechniqueCd) {
        case 'RT00000001':
          ibmTaskTypeCd = 'ITT0000001'
          ibmTaskUnderTypeCd = 'ITTU000001'
          break;
        case 'RT00000005':
          ibmTaskTypeCd = 'ITT0000005'
          ibmTaskUnderTypeCd = 'ITTU000005'
          break;
        case 'RT00000010':
          ibmTaskTypeCd = 'ITT0000010'
          ibmTaskUnderTypeCd = 'ITTU000010'
          break;
        case 'RT00000015':
          ibmTaskTypeCd = 'ITT0000015'
          ibmTaskUnderTypeCd = 'ITTU000015'
          break;
        case 'RT00000020':
          ibmTaskTypeCd = 'ITT0000020'
          ibmTaskUnderTypeCd = 'ITTU000020'
          break;
        case 'RT00000025':
          ibmTaskTypeCd = 'ITT0000023'
          ibmTaskUnderTypeCd = 'ITTU000030'
          break;
      }
      let requestContents = props.row.assessItem;
      this.popupOptions.title = 'LBLIMPRREQUEST';
      this.popupOptions.param = {
        requestContents: requestContents,
        relationTableKey: props.row.ramHazopAssessScenarioId,
        ibmTaskTypeCd: ibmTaskTypeCd,
        ibmTaskUnderTypeCd: ibmTaskUnderTypeCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/sop/ibm/imprRequest.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddImprPopup;
    },
    closeAddImprPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;

      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          let sData = this.requestImprRow.sopImprovementIds ? this.requestImprRow.sopImprovementIds : '';
          if (sData.indexOf(item.sopImprovementId) === -1) {
            this.requestImprRow.sopImprovementIds = this.requestImprRow.sopImprovementIds ? 
              this.requestImprRow.sopImprovementIds + ',' + item.sopImprovementId : item.sopImprovementId
            this.requestImprRow.ibmStepNames = this.requestImprRow.ibmStepNames ? 
              this.requestImprRow.ibmStepNames + ',' + item.ibmStepName : item.ibmStepName
          }
        })
        this.$refs['table'].$refs['compo-table'].resetVirtualScroll();
        this.research();
      }
    },
  }
};
</script>